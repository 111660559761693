export const numericAlphaHybrid = (valueA, valueB) => {
    // If either value is not a number, we want to consider it as a string.
    if (isNaN(valueA) || isNaN(valueB)) {
        if (valueA == valueB) {
            return 0
        }
        return valueA > valueB ? 1 : -1
    }
    // If both values are numbers, return the difference.
    return valueA - valueB
}
