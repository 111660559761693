<template>
    <base-dialog v-model="opened" max-width="90%" :confirm-action="uploadFile" :title="title">
        <template v-slot:activator="attrs">
            <slot name="activator" v-bind="attrs" />
        </template>

        <template v-slot:content>
            <div
                class="d-flex flex justify-center align-center flex-column flex-grow-1"
                style="height: 70vh"
            >
                <v-card-title class="font-weight-bold"> Import multiple sites </v-card-title>
                <v-icon color="primary" x-large> fal fa-school </v-icon>
                <v-card-actions style="height: 100%; width: 100%">
                    <file-upload ref="fileUpload" :stage-file="stageFile" />
                </v-card-actions>
            </div>
            <v-divider />
        </template>
    </base-dialog>
</template>

<script>
import FileUpload from '@/components/file/FileUpload'
import api from '@/api/file-upload'
import { mapGetters } from 'vuex'
import { errorSnackbar, infoSnackbar } from '@/helpers/snackbar'
import BaseDialog from '@/components/modal/BaseDialog'

export default {
    components: {
        BaseDialog,
        FileUpload,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        file: null,
        saving: false,
        opened: false,
        title: 'Add Site(s) | Import',
    }),
    computed: {
        ...mapGetters({ lea: 'getDistrictLea' }),
    },
    methods: {
        stageFile: async function (file) {
            this.file = file
        },
        uploadFile: async function () {
            this.saving = true
            const formData = new FormData()
            formData.append('file', this.file, this.file.name)
            formData.append('lea', this.lea)

            const resp = await api.upload(formData)
            if (resp.errors) {
                await errorSnackbar({
                    subtext: `File could not be uploaded: ${JSON.stringify(resp.errors)}`,
                })
            } else {
                await infoSnackbar({
                    message: 'In Progress',
                    subtext:
                        'Site upload is in progress, please refresh the page after two minutes!',
                })
            }

            this.saving = false
            this.opened = false
            this.$emit('showAddSiteDialog', false)

            this.$refs.fileUpload.remove()
        },
    },
}
</script>
