<template>
    <div class="d-flex align-center justify-center flex-column flex-grow-1 fill-height">
        <v-sheet
            outlined
            rounded="lg"
            :height="height"
            :width="width"
            style="border-style: dashed; border-width: 3px"
            class="d-flex align-center justify-center flex-column"
            :color="dragOver ? 'grey lighten-3' : null"
            @drop.prevent="droppedFile"
            @dragenter="dragOver = !dragOver"
            @dragleave="dragOver = !dragOver"
            @dragover.prevent
        >
            <v-spacer />
            <v-icon x-large color="primary">fal fa-folder-open</v-icon>
            <div>
                <h4>Drag a file here</h4>
                <small>or click Upload File</small>
            </div>
            <div>
                <v-chip v-for="file in files" :key="file.name">
                    <v-icon color="primary">fal fa-file</v-icon>
                    <div>{{ file.name }}</div>
                    <v-spacer />
                    <v-btn icon small @click="remove">
                        <v-icon small color="primary">fal fa-times</v-icon>
                    </v-btn>
                </v-chip>
            </div>
            <v-spacer />
            <v-btn color="primary" @click="$refs.uploader.click()">
                <v-icon class="mr-1">fal fa-upload</v-icon>Upload File
            </v-btn>
            <input ref="uploader" class="d-none" type="file" @change="clickedFile" />
            <v-spacer />
        </v-sheet>
    </div>
</template>

<script>
export default {
    props: {
        height: {
            type: String,
            default: '60%',
        },
        width: {
            type: String,
            default: '50%',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        stageFile: {
            type: Function,
            default: () => {
                return () => {}
            },
        },
    },
    data: () => ({
        dragOver: false,
        files: [],
    }),
    methods: {
        remove: function () {
            this.files = []
            this.$refs.uploader.value = null
            this.stageFile(null)
        },
        droppedFile: function (e) {
            this.dragOver = false
            this.addFile(e.dataTransfer.files)
        },
        clickedFile: function (e) {
            this.addFile(e.target.files)
        },
        addFile: function (files) {
            this.files = [...files]

            this.stageFile(this.files[0])
        },
    },
}
</script>