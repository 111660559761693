var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"title":_vm.title,"max-width":"696","view-only":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary ml-4",attrs:{"disabled":!_vm.hasCreateRole}},'v-btn',attrs,false),on),[_vm._v("Add Site(s) ")])]}},{key:"content",fn:function(){return [_c('v-card-actions',{staticClass:"pa-0"},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"ma-4",attrs:{"justify":"center","align":"center","cols":"12"}},[(!_vm.hasApi)?_c('v-alert',{staticClass:"ma-0",staticStyle:{"color":"rgba(176, 96, 0, 1)","width":"100%"},attrs:{"dense":"","border":"left","color":"#fef7e0","dismissible":""},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c('v-btn',{staticClass:"alert-message",attrs:{"light":"","icon":""},on:{"click":function($event){_vm.displayed = false}}},[_c('v-icon',{attrs:{"color":"rgba(176, 96, 0, 1)"}},[_vm._v("fal fa-times")])],1)]},proxy:true}],null,false,3785312546)},[_c('strong',[_vm._v("Alert!")]),_vm._v(" API is not setup yet. "),_c('router-link',{staticStyle:{"color":"rgba(176, 96, 0, 1)"},attrs:{"to":{ name: 'sis-imports-api-setup' }}},[_vm._v("Please Set Up an API")]),_vm._v(" to import sites from API. ")],1):_vm._e()],1),_c('v-row',{staticStyle:{"max-width":"552px","margin":"auto"},attrs:{"justify":"center","cols":"12"}},[_c('v-col',[_c('site-dialog',{on:{"close":function($event){_vm.opened = false}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button-icon-card',_vm._g({attrs:{"icon":"fal fa-school","sub-icon":"fas fa-plus-circle"}},on),[_vm._v(" Add Site(s)"),_c('br'),_vm._v("Manually ")])]}}])})],1),_c('v-col',[_c('import-site-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button-icon-card',_vm._g(_vm._b({attrs:{"icon":"fal fa-school","sub-icon":"fas fa-arrow-circle-down"}},'button-icon-card',attrs,false),on),[_vm._v(" Import Sites "),_c('br'),_vm._v(" from File ")])]}}]),model:{value:(_vm.openImportDialog),callback:function ($$v) {_vm.openImportDialog=$$v},expression:"openImportDialog"}})],1),_c('v-col',[_c('button-icon-card',{attrs:{"sub-icon":"fas fa-arrow-circle-right","icon":"fal fa-school","disabled":!_vm.hasApi,"warn":!_vm.hasApi},on:{"click":_vm.importFromApi}},[_vm._v("Import Sites "),_c('br'),_vm._v(" from API ")])],1)],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_vm._v(" ")]},proxy:true}]),model:{value:(_vm.opened),callback:function ($$v) {_vm.opened=$$v},expression:"opened"}})}
var staticRenderFns = []

export { render, staticRenderFns }