<template>
    <base-dialog v-model="opened" :title="title" max-width="696" view-only>
        <template v-slot:activator="{ on, attrs }">
            <v-btn :disabled="!hasCreateRole" v-bind="attrs" class="primary ml-4" v-on="on"
                >Add Site(s)
            </v-btn>
        </template>

        <template v-slot:content>
            <v-card-actions class="pa-0">
                <v-container class="pa-0">
                    <v-row justify="center" align="center" cols="12" class="ma-4">
                        <v-alert
                            v-if="!hasApi"
                            style="color: rgba(176, 96, 0, 1); width: 100%"
                            dense
                            border="left"
                            color="#fef7e0"
                            class="ma-0"
                            dismissible
                        >
                            <template v-slot:close>
                                <v-btn light class="alert-message" icon @click="displayed = false">
                                    <v-icon color="rgba(176, 96, 0, 1)">fal fa-times</v-icon>
                                </v-btn>
                            </template>
                            <strong>Alert!</strong> API is not setup yet.
                            <router-link
                                style="color: rgba(176, 96, 0, 1)"
                                :to="{ name: 'sis-imports-api-setup' }"
                                >Please Set Up an API</router-link
                            >
                            to import sites from API.
                        </v-alert>
                    </v-row>
                    <v-row justify="center" cols="12" style="max-width: 552px; margin: auto">
                        <v-col>
                            <site-dialog @close="opened = false">
                                <template #activator="{ on }">
                                    <button-icon-card
                                        icon="fal fa-school"
                                        sub-icon="fas fa-plus-circle"
                                        v-on="on"
                                    >
                                        Add Site(s)<br />Manually
                                    </button-icon-card>
                                </template>
                            </site-dialog>
                        </v-col>
                        <v-col>
                            <import-site-dialog v-model="openImportDialog">
                                <template v-slot:activator="{ on, attrs }">
                                    <button-icon-card
                                        icon="fal fa-school"
                                        sub-icon="fas fa-arrow-circle-down"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        Import Sites <br />
                                        from File
                                    </button-icon-card>
                                </template>
                            </import-site-dialog>
                        </v-col>
                        <v-col>
                            <button-icon-card
                                sub-icon="fas fa-arrow-circle-right"
                                icon="fal fa-school"
                                :disabled="!hasApi"
                                :warn="!hasApi"
                                @click="importFromApi"
                                >Import Sites <br />
                                from API
                            </button-icon-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </template>
        <template #footer>&nbsp;</template>
    </base-dialog>
</template>

<script>
import BaseDialog from '@/components/modal/BaseDialog'
import ImportSiteDialog from '@/views/Site/ImportSiteDialog'
import ButtonIconCard from '@/components/cards/ButtonIconCard'
import { ROLE_SETUP_SITE_CREATE } from '@/helpers/security/roles'
import { mapGetters } from 'vuex'
import { currentSchoolYear } from '@/helpers/form/formatting'
import sisSyncNow from '@/mixins/SisImports/syncNow'
import SiteDialog from '@/views/Site/SiteDialog'

export default {
    components: {
        ImportSiteDialog,
        BaseDialog,
        ButtonIconCard,
        SiteDialog,
    },
    mixins: [sisSyncNow],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Add Sites(s)',
        },
    },
    data: () => ({
        openImportDialog: false,
        opened: false,
        hasApi: true,
    }),

    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            district: 'getSelectedDistrict',
        }),
        hasCreateRole() {
            return this.hasRole(ROLE_SETUP_SITE_CREATE)
        },
    },
    async mount() {
        const site = await this.fetchSisProviderConfig({
            id: this.districtId,
            lea: this.lea,
        })

        this.hasApi = site.cfgSisSystemId
    },
    methods: {
        showDialog(val) {
            this.opened = val
        },
        async importFromApi() {
            const resp = this.syncNow({
                lea: this.lea,
                data: {
                    schoolsOnly: true,
                    // we wouldn't need these if we weren't using the rest middleware
                    // we're taking either a "valid (numeric)" sis year or the current school year.
                    schoolYearStart: parseInt(this.district.sisYear)
                        ? this.district.sisYear
                        : currentSchoolYear(),
                    schoolYearEnd: parseInt(this.district.sisYear)
                        ? this.district.sisYear
                        : currentSchoolYear(),
                },
            })
            this.opened = false
            this.responseSnackBar(resp)
        },
    },
}
</script>
