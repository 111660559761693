import api from '@/api/api'

export default {
    async upload(formData) {
        try {
            let resp = await api.post('/api/file-upload', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })

            return resp.data
        } catch (err) {
            throw 'Error uploading files'
        }
    },
}
