import { numericAlphaHybrid } from '@/helpers/comparator'

export default [
    {
        checkboxSelection: true,
        headerName: 'School Name',
        field: 'schoolName',
        width: 250,
        pinned: 'left',
    },
    {
        headerName: 'Console Internal ID',
        field: 'id',
        initialHide: true,
    },
    {
        headerName: 'Site Id',
        field: 'ncesSchoolCode',
        comparator: numericAlphaHybrid,
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'State School ID',
        field: 'stateId',
        comparator: numericAlphaHybrid,
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Active Status',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'School Type',
        field: 'schoolType',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'School Code',
        field: 'schoolCode',
        comparator: numericAlphaHybrid,
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'City',
        field: 'city',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'State',
        field: 'state',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Low Grade Level',
        field: 'lowGrade',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'High Grade Level',
        field: 'highGrade',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Blocked',
        field: 'blockImport',
        cellRendererFramework: 'ChipBoolean',
        cellRendererParams: { inverted: true },
        keyCreator: ({ value }) => (value ? 'Yes' : 'No'),
        filter: 'agSetColumnFilter',
        filterParams: {
            suppressMiniFilter: true,
            suppressSelectAll: true,
            defaultToNothingSelected: true,
            values: ['Yes', 'No'],
        },
    },
    {
        headerName: 'Use Master Schedule',
        field: 'doNotUseMasterSchedule',
        cellRendererFramework: 'ChipBoolean',
        cellRendererParams: { inverted: true, invertedValue: true },
        keyCreator: ({ value }) => (value ? 'No' : 'Yes'),
        filter: 'agSetColumnFilter',
        filterParams: {
            suppressMiniFilter: true,
            suppressSelectAll: true,
            defaultToNothingSelected: true,
            values: ['Yes', 'No'],
        },
    },
    {
        cellRendererFramework: 'RowActionButtons',
        cellRendererParams: {
            dialogButtons: [
                {
                    btnText: 'Edit',
                    icon: 'fal fa-pencil',
                    dialog: 'SiteDialog',
                    role: 'ROLE_SETUP_SITE_EDIT',
                },
            ],
        },
        width: 100,
        pinned: 'right',
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        hide: false,
        suppressMenu: true,
        sortable: false,
        filter: false,
    },
]
