import { errorSnackbar, successSnackbar } from '@/helpers/snackbar'
import { mapActions, mapGetters } from 'vuex'

const syncNow = {
    computed: {
        ...mapGetters({
            sisProviders: 'sisSystem/getItems',
            districtId: 'getDistrictId',
            lea: 'getDistrictLea',
        }),
    },
    methods: {
        async responseSnackBar(resp) {
            if (!resp.errors) {
                await successSnackbar({
                    subtext:
                        'The import has been initiated. Please allow 5 minutes to complete the import process',
                })
            } else {
                const errors = resp.errors.map((e) => e.message).join(', ')

                await errorSnackbar({
                    subtext: `An error occurred while attempting to sync: ${errors}`,
                })
            }
        },

        ...mapActions({
            syncNow: 'sisImports/sisSyncNow',
            fetchSisProviderConfig: 'sisApiConfig/get',
        }),
    },
}

export default syncNow
