<template>
    <parent-layout title="Site Management">
        <template #actions>
            <add-site-dialog />
        </template>
        <ag-grid-server-side
            id="siteMgmt"
            :server-side-datasource="serverSideDatasource"
            server-side-store-type="full"
            :set-grid-api="setGridApi"
            :column-defs="columnDefs"
        >
            <template #multi-select-actions>
                <v-btn
                    :disabled="!hasAccessRole"
                    plain
                    @click="updateSites({ doNotUseMasterSchedule: true })"
                >
                    Do not use master schedule
                </v-btn>
                <v-btn
                    :disabled="!hasAccessRole"
                    plain
                    @click="updateSites({ doNotUseMasterSchedule: false })"
                >
                    Use master schedule
                </v-btn>
                <v-btn :disabled="!hasAccessRole" plain @click="updateSites({ blockImport: true })">
                    Block Imports
                </v-btn>
                <v-btn
                    :disabled="!hasAccessRole"
                    plain
                    @click="updateSites({ blockImport: false })"
                >
                    Allow Imports
                </v-btn>
            </template>
        </ag-grid-server-side>
        <!--</ag-grid-loader>-->
    </parent-layout>
</template>

<script>
import AgGridServerSide from '@/components/table/AgGridServerSide'
import ColumnDef from '@/helpers/ag-grid/columnDef/sitesOverview'
import graphQlDataSource from '@/helpers/ag-grid/datasource/graphql'
import ParentLayout from '@/components/layout/ParentLayout'
import { mapActions, mapGetters } from 'vuex'
import AddSiteDialog from '@/views/Site/AddSiteDialog'
import { ROLE_SETUP_DISTRICT_EDIT } from '@/helpers/security/roles'

export default {
    name: 'SiteOverview',
    components: {
        ParentLayout,
        AgGridServerSide,
        AddSiteDialog,
    },
    data: () => ({
        columnDefs: ColumnDef,
        gridApi: null,
        openModel: false,
    }),
    computed: {
        ...mapGetters({
            fetching: 'site/getFetching',
            lea: 'getDistrictLea',
            hasRole: 'auth/hasRole',
        }),
        hasAccessRole() {
            return this.hasRole(ROLE_SETUP_DISTRICT_EDIT)
        },
    },
    methods: {
        ...mapActions('site', ['patchMultiple']),
        serverSideDatasource: function () {
            const fetchRows = async () => {
                await this.$store.dispatch('site/get', { lea: this.lea })
            }

            return {
                getRows: graphQlDataSource.fullStore(fetchRows, 'site/getItems').getRows,
            }
        },
        setGridApi: function (gridApi) {
            this.gridApi = gridApi
        },
        updateSites: async function (options) {
            const selected = this.gridApi.getSelectedRows()
            const update = selected.map((data) => ({ ...data, ...options }))
            this.gridApi.deselectAll()
            await this.patchMultiple({ data: update, lea: this.lea })
            this.gridApi.refreshServerSideStore({ purge: true })
        },
    },
}
</script>
