<template>
    <v-card :class="elClass" width="160" height="160" v-bind="$attrs" outlined v-on="$listeners">
        <v-icon v-if="warn" class="status-icon status-icon--warn">fas fa-exclamation-circle</v-icon>
        <v-icon v-if="selected" class="status-icon status-icon--selected">
            fas fa-check-circle
        </v-icon>
        <img v-if="image" :src="image" class="image" />
        <v-icon v-if="icon" :size="46" color="black" class="icon">{{ icon }}</v-icon>
        <v-icon v-if="subIcon" :size="20" class="sub-icon">{{ subIcon }}</v-icon>
        <v-card-title class="card-title pa-0">
            <slot>
                {{ title }}
            </slot>
        </v-card-title>
    </v-card>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        subIcon: {
            type: String,
            default: '',
        },
        warn: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
    },
    computed: {
        elClass() {
            let elClass = 'justify-center flex d-flex flex-column'

            if (this.warn) {
                elClass += ' warn'
            }

            if (this.selected) {
                elClass += ' selected'
            }

            return elClass
        },
    },
}
</script>

<style scoped>
.warn {
    border-color: #e7ca9d;
}

.status-icon {
    position: absolute;
    top: 12px;
    left: 12px;
    font-size: 14px;
}

.status-icon--warn {
    color: rgba(231, 202, 157, 1);
}

.status-icon--selected {
    color: rgba(73, 91, 143, 1);
}

.sub-icon {
    text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white, 2px 2px 0 white;
    position: absolute;
    left: calc(50% - 20px / 2 + 28px);
    top: calc(50% - 20px / 2 - 5px);
}
.card-title {
    justify-content: center;
    position: absolute;
    width: 108px;
    height: 38px;
    left: 26px;
    top: 99px;

    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    align-self: center;
    word-break: normal;
}

.icon {
    position: absolute;
    width: 62px;
    height: 50px;
    left: 49px;
    top: 30px;
}

.selected {
    background-color: rgba(204, 226, 233, 0.5);
}

.image {
    position: absolute;
    max-width: 62px;
    max-height: 60px;
    left: 49px;
    top: 30px;
}
</style>
